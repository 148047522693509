import "./App.css";
import { ReactPhotoSphereViewer } from "react-photo-sphere-viewer";
import React from "react";
import { GyroscopePlugin } from "@photo-sphere-viewer/gyroscope-plugin";

function App() {
  const plugins = [GyroscopePlugin];

  return (
    <div className="App">
      <ReactPhotoSphereViewer
        src="mirante.jpg"
        height={"100vh"}
        width={"100%"}
        plugins={plugins}
      ></ReactPhotoSphereViewer>
    </div>
  );
}

export default App;
